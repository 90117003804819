import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  MouseEvent,
  ChangeEvent,
} from "react";
import { MRT_Header, MRT_RowData, MRT_TableInstance } from "../../types";
import { MRT_MenuItem } from "../menuItem";
import debounce from "debounce";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

interface Props<TData extends MRT_RowData> {
  header: MRT_Header<TData>;
  rangeFilterIndex?: number;
  table: MRT_TableInstance<TData>;
}

export const MRT_FilterTextField = <TData extends MRT_RowData>({
  header,
  rangeFilterIndex,
  table,
  ...rest
}: Props<TData>) => {
  const {
    options: {
      enableColumnFilterModes,
      columnFilterModeOptions,
      icons: { FilterListIcon, CloseIcon },
      localization,
      manualFiltering,
      muiFilterTextFieldProps,
    },
    refs: { filterInputRefs },
    setColumnFilterFns,
  } = table;
  const { column } = header;
  const { columnDef } = column;

  const mTableHeadCellFilterTextFieldProps =
    muiFilterTextFieldProps instanceof Function
      ? muiFilterTextFieldProps({
          column,
          table,
          rangeFilterIndex,
        })
      : muiFilterTextFieldProps;

  const mcTableHeadCellFilterTextFieldProps =
    columnDef.muiFilterTextFieldProps instanceof Function
      ? columnDef.muiFilterTextFieldProps({
          column,
          table,
          rangeFilterIndex,
        })
      : columnDef.muiFilterTextFieldProps;

  const textFieldProps = {
    ...mTableHeadCellFilterTextFieldProps,
    ...mcTableHeadCellFilterTextFieldProps,
  };

  const isRangeFilter =
    columnDef.filterVariant === "range" || rangeFilterIndex !== undefined;
  const isSelectFilter = columnDef.filterVariant === "select";
  const isMultiSelectFilter = columnDef.filterVariant === "multi-select";
  const isTextboxFilter =
    columnDef.filterVariant === "text" ||
    (!isSelectFilter && !isMultiSelectFilter);
  const currentFilterOption = columnDef._filterFn;
  const filterChipLabel = ["empty", "notEmpty"].includes(currentFilterOption)
    ? //@ts-ignore
      localization[
        `filter${
          currentFilterOption?.charAt?.(0)?.toUpperCase() +
          currentFilterOption?.slice(1)
        }`
      ]
    : "";
  const filterPlaceholder = !isRangeFilter
    ? textFieldProps?.placeholder ??
      localization.filterByColumn?.replace("{column}", String(columnDef.header))
    : rangeFilterIndex === 0
    ? localization.min
    : rangeFilterIndex === 1
    ? localization.max
    : "";
  const allowedColumnFilterOptions =
    columnDef?.columnFilterModeOptions ?? columnFilterModeOptions;
  const showChangeModeButton =
    enableColumnFilterModes &&
    columnDef.enableColumnFilterModes !== false &&
    !rangeFilterIndex &&
    (allowedColumnFilterOptions === undefined ||
      !!allowedColumnFilterOptions?.length);

  const facetedUniqueValues = column.getFacetedUniqueValues();

  const filterSelectOptions = useMemo(
    () =>
      columnDef.filterSelectOptions ??
      ((isSelectFilter || isMultiSelectFilter) && facetedUniqueValues
        ? Array.from(facetedUniqueValues.keys())
            .filter((value) => value !== null && value !== undefined)
            .sort((a, b) => a.localeCompare(b))
        : undefined),
    [
      columnDef.filterSelectOptions,
      facetedUniqueValues,
      isMultiSelectFilter,
      isSelectFilter,
    ]
  );

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [filterValue, setFilterValue] = useState<string | string[]>(() =>
    isMultiSelectFilter
      ? (column.getFilterValue() as string[]) || []
      : isRangeFilter
      ? (column.getFilterValue() as [string, string])?.[
          rangeFilterIndex as number
        ] || ""
      : (column.getFilterValue() as string) ?? ""
  );

  const handleChangeDebounced = useCallback(
    debounce(
      (newValue: any) => {
        if (isRangeFilter) {
          column.setFilterValue((old: Array<Date | null | number | string>) => {
            const newFilterValues = old ?? ["", ""];
            newFilterValues[rangeFilterIndex as number] = newValue ?? undefined;
            return newFilterValues;
          });
        } else {
          column.setFilterValue(newValue ?? undefined);
        }
      },
      isTextboxFilter ? (manualFiltering ? 400 : 200) : 1
    ),
    []
  );

  const handleTextFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue =
      textFieldProps.type === "date"
        ? event.target.valueAsDate
        : textFieldProps.type === "number"
        ? event.target.valueAsNumber
        : event.target.value;
    handleChange(newValue);
    textFieldProps?.onChange?.(event);
  };
  const handleChange = (newValue: any) => {
    setFilterValue(newValue ?? "");
    handleChangeDebounced(newValue);
  };

  const handleClear = () => {
    if (isMultiSelectFilter) {
      setFilterValue([]);
      column.setFilterValue([]);
    } else if (isRangeFilter) {
      setFilterValue("");
      column.setFilterValue((old: [string | undefined, string | undefined]) => {
        const newFilterValues = (Array.isArray(old) && old) || ["", ""];
        newFilterValues[rangeFilterIndex as number] = undefined;
        return newFilterValues;
      });
    } else {
      setFilterValue("");
      column.setFilterValue(undefined);
    }
  };

  const handleClearEmptyFilterChip = () => {
    setFilterValue("");
    column.setFilterValue(undefined);
    setColumnFilterFns((prev) => ({
      ...prev,
      [header.id]: allowedColumnFilterOptions?.[0] ?? "fuzzy",
    }));
  };

  const handleFilterMenuOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const isMounted = useRef(false);

  useEffect(() => {
    if (isMounted.current) {
      const filterValue = column.getFilterValue();
      if (filterValue === undefined) {
        handleClear();
      } else if (isRangeFilter && rangeFilterIndex !== undefined) {
        setFilterValue((filterValue as [string, string])[rangeFilterIndex]);
      } else {
        setFilterValue(filterValue as string);
      }
    }
    isMounted.current = true;
  }, [column.getFilterValue()]);

  if (columnDef.Filter) {
    return (
      <>{columnDef.Filter?.({ column, header, rangeFilterIndex, table })}</>
    );
  }

  // @ts-ignore
  // @ts-ignore
  return (
    <>
      <div className="headless_relative-mt-2">
        <input
          type="text"
          {...textFieldProps}
          onChange={handleTextFieldChange}
          onClick={(e) => e.stopPropagation()}
          disabled={!!filterChipLabel}
          value={filterValue ?? ""}
          select={(isSelectFilter || isMultiSelectFilter).toString()}
          className={classNames(
            `headless_filter-input`,
            filterChipLabel ? `headless_w-0` : ``,
            isRangeFilter
              ? "headless_min-w-100"
              : !filterChipLabel
              ? "headless_min-w-120"
              : "headless_w-auto"
          )}
          ref={(inputRef: HTMLInputElement) => {
            filterInputRefs.current[`${column.id}-${rangeFilterIndex ?? 0}`] =
              inputRef;
            if (textFieldProps.inputRef) {
              textFieldProps.inputRef = inputRef;
            }
          }}
          placeholder={
            filterChipLabel || isSelectFilter || isMultiSelectFilter
              ? undefined
              : filterPlaceholder
          }
        />

        <div className={"headless_filter-text-div"}>
          <div
            className="headless_tooltip_bottom"
            data-tip={localization.clearFilter}
          >
            {!filterChipLabel && (
              <button
                className={"headless_expandBtn-tooltipBtn"}
                aria-label={localization.clearFilter}
                disabled={!filterValue?.toString()?.length}
                onClick={handleClear}
              >
                <CloseIcon className={"h-5 w-5"} />
              </button>
            )}
          </div>
        </div>
        {/*@ts-ignore*/}
        <>
          {isMultiSelectFilter
            ? (selected: any) =>
                !selected?.length ? (
                  <div className={"headless_opacity-50"}>{filterPlaceholder}</div>
                ) : (
                  <div className={"headless_flex-wrap-gap"}>
                    {(selected as string[])?.map((value) => {
                      const selectedValue = filterSelectOptions?.find(
                        (option) =>
                          option instanceof Object
                            ? option.value === value
                            : option === value
                      );
                      return (
                        <button
                          type="button"
                          key={value}
                          className="headless_filter-text-btn"
                        >
                          label=
                          {selectedValue instanceof Object
                            ? selectedValue.text
                            : selectedValue}
                        </button>
                      );
                    })}
                  </div>
                )
            : ""}
        </>
      </div>
      {(isSelectFilter || isMultiSelectFilter) && (
        <MRT_MenuItem disabled hidden value="">
          <div className={"headless_opacity-50"}>{filterPlaceholder}</div>
        </MRT_MenuItem>
      )}
      {textFieldProps.children ??
        filterSelectOptions?.map((option) => {
          if (!option) return "";
          let value;
          let text;
          if (typeof option !== "object") {
            value = option;
            text = option;
          } else {
            value = option.value;
            text = option.text;
          }
          return (
            <MRT_MenuItem
              key={value}
              className={"headless_flex-m-g2"}
              value={value}
            >
              {isMultiSelectFilter && (
                <input
                  type="checkbox"
                  checked={(
                    (column.getFilterValue() ?? []) as string[]
                  ).includes(value)}
                  className="headless_filter-text-checkbox"
                />
              )}
              {text}{" "}
              {!columnDef.filterSelectOptions &&
                `(${facetedUniqueValues.get(value)})`}
            </MRT_MenuItem>
          );
        })}
    </>
  );
};
